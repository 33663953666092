.ff-login {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    padding: 3rem 0;
}

.ff-login p {
    text-align: center;
}
