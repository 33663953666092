.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: 4.5rem;
    padding: 0 1rem;

    color: rgba(255, 255, 255, 0.6);
    background-color: rgba(22, 22, 29, 0.2);
}

.footer p {
    display: block;

    width: 100%;
    margin: 0.5125rem 0;

    font-size: 0.875rem;
    line-height: 1rem;
    text-align: center;
}

.footer p span {
    color: var(--color-red);
}

@media screen and (min-width: 640px) {
    .footer {
        flex-direction: row;

        height: 3rem;
    }

    .footer p {
        width: 50%;
        margin: 0;
    }

    .footer p:first-child {
        text-align: left;
    }

    .footer p:last-child {
        text-align: right;
    }
}
