a.link {
  display: inline-block;

  font-style: normal;
  font-weight: 400;

  color: var(--color-white);
  text-decoration: none;
  border-bottom: 0.1rem solid var(--color-red);
}

a.link:hover,
a.link:active,
a.link:focus,
a.link:visited {
  cursor: pointer;
  text-decoration: none;
}

a.link:hover {
  border-bottom-color: var(--color-red-accent);
}

a.link:focus {
  outline: 0.1rem dotted var(--color-red-accent);
}
