.ff-loading {
    position: relative;

    display: block;
    width: 3rem;
    height: 1.5rem;

    margin: 0 auto;
    padding: 0.5rem 0;

    background-color: transparent;
}

.ff-loading div {
    position: absolute;
    top: 0.5rem;

    display: block;
    width: 0.5rem;
    height: 0.5rem;

    background-color: var(--color-background-light);

    transition: transform;
}

.ff-loading div:nth-child(1) {
    left: 0;
    animation: 0.9s linear 0s infinite load;
}

.ff-loading div:nth-child(2) {
    left: calc(50% - 0.25rem);
    animation: 0.9s linear 0.3s infinite load;
}

.ff-loading div:nth-child(3) {
    right: 0;
    animation: 0.9s linear 0.6s infinite load;
}

@keyframes load {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1);
        background-color: var(--color-background-light);
    }


    50% {
        transform: scale(1.2);
        background-color: var(--color-red);
    }

    75% {
        transform: scale(1);
        background-color: var(--color-background-light);
    }

    100% {
        transform: scale(1);
    }
}
