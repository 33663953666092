.ff-select {
    position: relative;
    display: block;

    padding: 0;

    font-size: 0.8rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-transform: uppercase;    
}

.ff-select::before {
    content: "\25BC";

    position: absolute;
    top: 1.25rem;
    right: 0rem;

    display: block;
    width: 1.5rem;
    height: 1.5rem;

    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;

    color: var(--color-background);
    background: transparent;
}

.ff-select .ff-select-trigger {
    display: block;

    width: 100%;
    height: 1.5rem;
    margin: 0;
    padding: 0 0.2rem;

    font-family: var(--font-family);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.3rem;
    text-align: left;

    color: var(--color-background);
    background-color: var(--color-white);
    border: 0.1rem solid var(--color-background-light);
    border-left-width: 1rem;
    border-radius: 0;

    cursor: pointer;
}

.ff-select .ff-select-trigger:hover,
.ff-select .ff-select-trigger:active {
    border-color: var(--color-background);
    cursor: pointer;
}

.ff-select .ff-select-trigger[disabled] {
    opacity: 0.5;
    cursor: default;
}

.ff-select .ff-select-options {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.ff-select label {
    display: block;

    margin: 0;
    padding: 0;

    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.25rem;
}

.ff-select .ff-select-options button {
    display: block;

    width: 100%;
    margin: 0;
    padding: 0 0.2rem;

    font-family: var(--font-family);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5rem;
    text-align: left;

    color: var(--color-white);
    background-color: var(--color-background-light);
    border: none;
    border-radius: 0;

    cursor: pointer;
}

.ff-select .ff-select-options button:hover,
.ff-select .ff-select-options button:active {
    color: var(--color-white);
    background-color: var(--color-background);
}

.ff-select .ff-select-options button.selected {
    color: var(--color-white);
    background-color: var(--color-red);
}
