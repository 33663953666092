.title {
  width: 100%;

  margin: 0;
  padding: 0 0 0 0.05rem;

  font-family: var(--font-family);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3rem;

  border-bottom: 0.2rem solid var(--color-background-light);

  text-align: left;
}

.title em {
  font-style: inherit;
  color: var(--color-red);
}
