.sidebar {
  position: absolute;
  left: 1rem;
  top: 0;

  padding: var(--base-grid-unit);

  background-color: var(--color-background-lighter);
  border: 0.1rem solid var(--color-background);
  box-shadow: 0.2rem 0.2rem 0px var(--color-background);

  transform: translateX(-360px);
  transition: transform 0.16s ease-in-out;

  z-index: 9999;
}

.sidebar.visible {
  transform: translateX(0);
}

@media screen and (min-width: 640px) {
  .sidebar {
    position: relative;
    left: 0;

    background-color: transparent;
    border: none;
    box-shadow: none;

    z-index: auto;
  }
}

.sidebar nav {
  min-height: calc(100vh - 15 * var(--base-grid-unit));
  border-right: 0.2rem solid var(--color-background-light);
}

.sidebar nav ul {
  list-style: none;
  margin: 0;
  padding: 0 1rem 1rem 0;
}

.sidebar nav ul li {
  margin: 0;
  padding: 0;

  line-height: 1.5rem;
  text-align: left;
}

.sidebar nav ul a {
  color: var(--color-white);
  text-decoration: none;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
}

.sidebar nav ul a:hover,
.sidebar nav ul a:active,
.sidebar nav ul a:focus {
  color: var(--color-red-accent);
}

.sidebar nav ul a.is-active {
  color: var(--color-red);
}
