.container {
  position: relative;
  min-height: 100vh;

  /* Top padding: height of header, bottom padding: height of footer */
  padding: 8rem 0 4.5rem 0;
}

.sidebar-menu {
  display: block;

  position: absolute;
  top: -3.3rem;
  left: 1rem;
}

@media screen and (min-width: 640px) {
  .container {
    padding-bottom: 3rem;
  }

  .sidebar-menu {
    display: none;
  }
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.content {
  position: relative;
  display: block;
}

@media screen and (min-width: 640px) {
  .content {
    display: flex;

    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
  }
}

.content .sidebar {
  width: 180px;
}

.content .outlet {
  width: 100vw;
  padding: 1rem;
}

@media screen and (min-width: 640px) {
  .content .outlet {
    width: calc(100vw - 180px);
  }
}
