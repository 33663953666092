.forgot-password {
  display: block;

  width: 100%;
  max-width: 280px;

  margin: 0 auto;
  padding: 3rem 0;

  text-align: center;
}
