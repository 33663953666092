.ff-input {
    position: relative;
}

.ff-input label {
    display: block;

    margin: 0;
    padding: 0;

    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-transform: uppercase;
}

.ff-input input {
    display: block;
    width: 100%;

    margin: 0;
    padding: 0 0.2rem 0 0.2rem;

    border: 0.1rem solid var(--color-background-light);
    border-left-width: 1rem;
    border-radius: 0;

    font-family: var(--font-family);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.ff-input input:focus {
  outline: none;
  border-color: var(--color-background);
}

.ff-input input[disabled] {
    opacity: 0.5;
    background-color: var(--color-white);
}
