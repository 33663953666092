.ff-button {
  display: inline-block;

  margin: 0;
  padding: 0 0.4rem;

  text-transform: uppercase;
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;

  background: transparent;
  background-color: var(--color-background-light);
  color: var(--color-white);

  border: 0.1rem solid var(--color-red);
  border-radius: 0;
  box-shadow: 0.2rem 0.2rem 0px var(--color-background);

  cursor: pointer;

  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

.ff-button:active {
  transform: translate(0.2rem, 0.2rem);
  box-shadow: 0rem 0rem 0px var(--color-background);
}

.ff-button:focus {
  outline: 0.1rem dotted var(--color-red-accent);
  outline-offset: -0.3rem;
}
