.ff-kanban-board {
    position: relative;
    display: flex;

    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    column-gap: 1rem;
}

.ff-kanban-board section {
    width: 100%;
}

@media screen and (min-width: 1024px) {
    .ff-kanban-board {
        flex-direction: row;
    }

    .ff-kanban-board section {
        width: 20%;
    }
}

.ff-kanban-board h3 {
    margin: 0 0 1rem 0;
    padding: 0 0.5rem;

    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;

    color: var(--color-white);
    background-color: var(--color-background-light);
    box-shadow: 0.2rem 0.2rem 0 var(--color-background);
    border: 0.1rem solid var(--color-background);
}

.ff-kanban-board ul {
    margin: 0;
    padding: 0;

    list-style: none;
}

.ff-kanban-board ul li {
    margin: 0 0 1rem 0;
    padding: 0;
}

.ff-kanban-board .ff-button {
    position: absolute;
    top: -3.2rem;
    right: 0;
}
