.ff-checkbox {
    display: block;

    width: 100%;
    height: 1.7rem;

    cursor: pointer;
}

.ff-checkbox.disabled {
    cursor: default;
}

.ff-checkbox .ff-checkbox-label,
.ff-checkbox .ff-checkbox-check {
    display: inline-block;
    vertical-align: middle;
}

.ff-checkbox .ff-checkbox-label {
    line-height: 1.7rem;
    user-select: none;
}

.ff-checkbox .ff-checkbox-check {
    position: relative;

    width: 1.7rem;
    height: 1.7rem;

    margin: 0 0.5rem 0 0;

    line-height: 1.5rem;

    background-color: var(--color-white);
    border: 0.1rem solid var(--color-background-light);
}

.ff-checkbox.disabled .ff-checkbox-check {
    opacity: 0.5;
}

.ff-checkbox .ff-checkbox-check::before {
    display: block;

    position: absolute;
    top: 0;
    left: 0;

    width: 1.5rem;
    height: 1.5rem;

    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;

    color: var(--color-background);
}

.ff-checkbox input[type="checkbox"] {
    width: 0;
    visibility: hidden;
}

.ff-checkbox input[type="checkbox"] ~ .ff-checkbox-check {
    content: " ";
}

.ff-checkbox input[type="checkbox"]:checked ~ .ff-checkbox-check::before {
    content: "\2713";
}
