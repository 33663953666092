.ff-kanban-task {
    position: relative;

    display: block;

    background-color: var(--color-background-light);
    border: 0.1rem solid var(--color-background);
    box-shadow: 0.2rem 0.2rem 0 var(--color-background);
}

.ff-kanban-task .ff-kanban-task-priority,
.ff-kanban-task .ff-kanban-task-assignee {
    font-size: 0.7rem;
    line-height: 1.2rem;
}

.ff-kanban-task .ff-kanban-task-priority {
    position: absolute;
    left: 0.3rem;
    right: 0;

    font-style: italic;
}

.ff-kanban-task .ff-kanban-task-assignee {
    margin: 0;
    padding: 0 0.3rem;

    text-align: right;

    background-color: rgba(22, 22, 29, 0.2);
    border-bottom: 0.1rem dotted var(--color-background);
}

.ff-kanban-task h4 {
    display: block;
    min-height: 3.05rem;
    margin: 0;
    padding: 0.1rem 0.3rem;

    font-family: var(--font-family);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.425rem;

    cursor: pointer;
}

.ff-kanban-task label {
    display: block;
    margin: 0;
    padding: 0 0.3rem 0 0;

    font-size: 0.8rem;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 1.2rem;

    text-align: left;

    border-top: 0.1rem solid var(--color-background);
}

.ff-kanban-task label span {
    display: inline-block;
    margin: 0 0.3rem 0 0;
    
    width: 1.2rem;
    height: 1.2rem;

    vertical-align: bottom;
}

.ff-kanban-task .link {
    display: block;
    margin: 0;
    padding: 0 0.3rem;

    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.5rem;

    text-align: center;

    background-color: var(--color-background);
}
