.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;

  padding: var(--base-grid-unit);
}

.user-info {
  width: 100%;

  text-align: right;
}

.user-info p {
  margin: 0.5rem 0 0 0;

  font-size: 1rem;
  line-height: 1rem;
}
