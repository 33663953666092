.ff-smart-table-container {
    position: relative;
}

.ff-smart-table-container .ff-title {
    margin: 0 0 0.5rem 0;
}

.ff-smart-table-container.has-create > .ff-button {
    position: absolute;
    top: 0;
    right: 0;
}

.ff-smart-table {
    display: table;
    table-layout: auto;
    width: 100%;

    border-collapse: collapse;
    border: 0.1rem solid var(--color-background);
    box-shadow: 0.2rem 0.2rem 0 var(--color-background);
}

@media screen and (min-width: 1024px) {
    .ff-smart-table {
        table-layout: fixed;
    }
}

.ff-smart-table th,
.ff-smart-table td {
    padding: 0 0.5rem;

    line-height: 3rem;

    border: none;
}

.ff-smart-table thead {
    text-align: left;

    color: var(--color-white);
    background-color: var(--color-background-light);
    border-bottom: 0.1rem solid var(--color-background);
}

.ff-smart-table thead th {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 2rem;
}

.ff-smart-table tbody {}

.ff-smart-table tbody td {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ff-smart-table tbody td.no-data {
    font-style: italic;
    text-align: center;
}

.ff-smart-table tbody td.is-loading {
    padding: 1.5rem;
}

.ff-smart-table tbody tr:nth-child(odd) {
    background-color: var(--color-background-light);
}

.ff-smart-table tbody tr:nth-child(even) {
    background-color: var(--color-background-lighter);
}

.ff-smart-table tfoot {
    position: relative;

    text-align: center;
    background-color: var(--color-background-light);
    border-top: 0.1rem solid var(--color-background);
}

.ff-smart-table tfoot td {
    line-height: 3rem;
}

.ff-smart-table tfoot .ff-button {
    margin: 0 0.25rem;
}

.ff-smart-table tfoot span {
    margin: 0 0.5rem;
}

.ff-smart-table tfoot .tfoot-total {
    position: absolute;
    right: 0.5rem;
}

.ff-smart-table .actions .ff-button:not(:last-child) {
    margin-right: 0.5rem;
}
