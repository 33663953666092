.ff-input-textarea {
    position: relative;
}

.ff-input-textarea label {
    display: block;

    margin: 0;
    padding: 0;

    font-size: 0.8rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-transform: uppercase;
}

.ff-input-textarea textarea {
    display: block;
    width: 100%;

    margin: 0;
    padding: 0 0.2rem 0 0.2rem;

    border: 0.1rem solid var(--color-background-light);
    border-left-width: 1rem;
    border-radius: 0;

    font-family: var(--font-family);
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2rem;

    resize: vertical;
}

.ff-input-textarea textarea:focus {
  outline: none;
  border-color: var(--color-background);
}
