.ff-title {
    display: block;
    height: 2rem;
    margin: 0 0 1.5rem 0;
    padding: 0;

    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;
}
