@import-normalize;

@font-face {
  font-family: "IBM Plex Mono";
  font-weight: 400;
  font-style: normal;
  src: url(./fonts/IBM_Plex_Mono/IBMPlexMono-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "IBM Plex Mono";
  font-weight: 400;
  font-style: italic;
  src: url(./fonts/IBM_Plex_Mono/IBMPlexMono-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "IBM Plex Mono";
  font-weight: 700;
  font-style: normal;
  src: url(./fonts/IBM_Plex_Mono/IBMPlexMono-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "IBM Plex Mono";
  font-weight: 700;
  font-style: italic;
  src: url(./fonts/IBM_Plex_Mono/IBMPlexMono-BoldItalic.ttf) format("truetype");
}

:root {
  /* Colours */
  --color-white: #e8e8ed;
  --color-background: #16161d;
  --color-background-light: #323243;
  --color-background-lighter: #4d4d66;
  --color-gray: #ebebf0;
  --color-red: #c14e57;
  --color-red-accent: #dd313f;

  /* Typography */
  --font-family: "IBM Plex Mono", monospace;
  --font-family-mono: "IBM Plex Mono", monospace;
  --base-font-size: 16px;
  --base-line-height: 24px;

  /* Grids */
  --base-grid-unit: 1rem;
}

/**
 * Reset and base
 */
* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  width: 100%;
  height: 100%;

  font-family: var(--font-family);
  font-size: var(--base-font-size);
  line-height: var(--base-line-height);

  color: var(--color-white);
  background-color: var(--color-background-lighter);
  background: radial-gradient(#4d4d66 0%, #323243 100%);
}

body {
  width: 100%;
  min-height: 100%;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAAXNSR0IArs4c6QAAAB5JREFUGFdjZEADjCC+gYHj/wsX9jOCaLAAMsBQAQAG3wtFgrCllQAAAABJRU5ErkJggg==);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

/**
 * General small things
 */
hr {
  display: block;

  width: 100%;
  height: 0.1rem;
  margin: 0 0 1rem 0;

  background-color: var(--color-background);
  border: none;
}
