.ff-message,
.ff-button,
.ff-input,
.ff-input-textarea,
.ff-select {
    margin: 0 0 1rem 0;
}

footer {
    text-align: center;
}

footer .ff-button {
    margin: 0;
}
