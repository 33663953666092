.ff-modal {
    display: none;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 0 0 1rem 0;

    background-color: rgba(22, 22, 29, 0.7);

    overflow-y: scroll;
    z-index: 999;
}

.ff-modal.visible {
    display: block;
}

.ff-modal-container {
    display: block;
    width: 100%;
    max-width: 480px;
    margin: 1.5rem auto;
    padding: 1rem;

    background-color: var(--color-background-lighter);
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAAXNSR0IArs4c6QAAAB5JREFUGFdjZEADjCC+gYHj/wsX9jOCaLAAMsBQAQAG3wtFgrCllQAAAABJRU5ErkJggg==);
    box-shadow: 0.2rem 0.2rem 0 var(--color-background);
}

.ff-modal-container header {
    position: relative;
    margin: 0 0 1rem 0;
}

.ff-modal-container h3 {
    margin: 0;
    padding: 0;

    font-family: var(--font-family);
    font-size: 1rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.5rem;

    border-bottom: 0.2rem solid var(--color-background);
}

.ff-modal-container .close-header {
    position: absolute;
    top: 0;
    right: 0;

    margin: 0;
    padding: 0 0.2rem;

    font-size: 0.875rem;
    font-family: var(--font-family);
    font-weight: 700;
    line-height: 1.5rem;
    text-transform: uppercase;

    color: var(--color-white);
    background: none;
    border: none;
    border-radius: 0;
}

.ff-modal-container .close-header:hover {
    cursor: pointer;
    color: var(--color-red);
}

.ff-modal-container .close-header:focus {
  outline: 0.1rem dotted var(--color-red-accent);
}
