.ff-form {
  display: block;

  width: 100%;
  max-width: 320px;

  margin: 0;
  padding: 0;
}

.ff-form .ff-message,
.ff-form .ff-input,
.ff-form .ff-checkbox,
.ff-form footer {
    margin: 1.5rem 0;
}

.ff-form footer {
    text-align: center;
}

.ff-form footer .ff-button {
    margin: 0 0.5rem;
}
